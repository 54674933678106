:root{
    --facebook:#3C5A99;
    --youtube:#f00;
    --whatsapp:#4AC959;
    --github:#333;
    --slack:linear-gradient(-120deg, #3eb991, #6ecadc, #e9a820, #e01563);
}

.facebook{
    background-color: var(--facebook);
    color:#fff;
}

.youtube{
    background-color: var(--youtube);
    color:#fff;
}

.whatsapp{
    background-color: var(--whatsapp);
    color:#fff;
}

.github{
    background-color: var(--github);
    color:#fff;
}

.slack{
    background: var(--slack);
    color:#fff;
}