/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.st0{font-family:'FootlightMTLight';}
.st1{font-size:83.0285px;}
.st2{fill:gray;}

svg{
  width: 100%;
    height: auto;
    text-align: center;
    fill: #18397a;
}
path#XMLID_5_ {
   
    fill: #18397a;
    -webkit-filter: url(#blurFilter4);
            filter: url(#blurFilter4);
}
path#XMLID_11_ ,path#XMLID_2_ {
    fill: #18397a;
}
.circle{
  -webkit-animation: out 2s infinite ease-out;
          animation: out 2s infinite ease-out;
  fill: #18397a;
}

#container{
  text-align:center;
}
.message{
	color:#18397a;
}
.message:after{
	content:"]";
}
.message:before{
	content:"[";
}

.message:after, .message:before {
  
  color: #18397a;
  font-size: 20px;
  -webkit-animation-name: opacity;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: opacity;
          animation-name: opacity;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
          margin:0 50px;
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes out {
  0% {r:1;  opacity: 0.9 ;}
  25%{r:5;  opacity: 0.3 ;}
  50%{r:10; opacity: 0.2 ;}
  75%{r:15;opacity:0.1;}
 	100% {r:20;opacity:0;}
}

@keyframes out {
  0% {r:1;  opacity: 0.9 ;}
  25%{r:5;  opacity: 0.3 ;}
  50%{r:10; opacity: 0.2 ;}
  75%{r:15;opacity:0.1;}
 	100% {r:20;opacity:0;}
}









  
  	

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0 , 0.2);
  border-left: 1.1em solid #90caf9;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
          animation: load8 1.1s infinite linear;
  content: "Loading";
}


/* animations*/
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
:root{
    --facebook:#3C5A99;
    --youtube:#f00;
    --whatsapp:#4AC959;
    --github:#333;
    --slack:linear-gradient(-120deg, #3eb991, #6ecadc, #e9a820, #e01563);
}

.facebook{
    background-color: #3C5A99;
    background-color: var(--facebook);
    color:#fff;
}

.youtube{
    background-color: #f00;
    background-color: var(--youtube);
    color:#fff;
}

.whatsapp{
    background-color: #4AC959;
    background-color: var(--whatsapp);
    color:#fff;
}

.github{
    background-color: #333;
    background-color: var(--github);
    color:#fff;
}

.slack{
    background: linear-gradient(-120deg, #3eb991, #6ecadc, #e9a820, #e01563);
    background: var(--slack);
    color:#fff;
}
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

/* google fonts */
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

#sidenav-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

html {
  position: relative;
  min-height: 100vh;
}

body {
  margin-bottom: 60px;
  background-color: #fff;
}

main {
  flex: 1 0 auto;
}

fieldset{
  border:none;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 40px;
}

.img-stick {
  object-fit: fill;
}

/* typewritter STYLES */
#typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  -webkit-animation: typing 6s steps(30, end);
          animation: typing 6s steps(30, end);
}

.jumbotronStyle {
  padding-bottom: 50px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.jumbotronStyle h1 {
  font-size: 30pt;
}

.gradient1 {
  background: #bdc3c7; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#2c3e50),
    to(#bdc3c7)
  );
  background: linear-gradient(
    to right,
    #2c3e50,
    #bdc3c7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient2 {
  background: #757f9a; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#d7dde8),
    to(#757f9a)
  );
  background: linear-gradient(
    to right,
    #d7dde8,
    #757f9a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient3 {
  background: #6190e8; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#a7bfe8),
    to(#6190e8)
  );
  background: linear-gradient(
    to right,
    #a7bfe8,
    #6190e8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient4 {
  background: #000046; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(#1cb5e0),
    to(#000046)
  );
  background: linear-gradient(
    to right,
    #1cb5e0,
    #000046
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* card*/
.center-cols > .col {
  float: none; /* disable the float */
  display: inline-block; /* make blocks inline-block */
  text-align: left;
  text-align: initial; /* restore text-align to default */
}

.brand-img{
  width: 100px;
  height: 100px;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}

.maps{
  width: 100%;
  height:300px;
  border: 0;
}

.margin-title{
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
}

/* The typing effect */
@-webkit-keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (min-height: 320px) {
  .ui.footer.form-page {
    /* Increased specificity for SO snippet priority */
    position: absolute;
    margin-top: 25px;
    bottom: 0;
    width: 100%;
  }
}

