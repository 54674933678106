@import "preload.css";
@import "Fab.css";

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

/* google fonts */
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

#sidenav-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

html {
  position: relative;
  min-height: 100vh;
}

body {
  margin-bottom: 60px;
  background-color: #fff;
}

main {
  flex: 1 0 auto;
}

fieldset{
  border:none;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 40px;
}

.img-stick {
  object-fit: fill;
}

/* typewritter STYLES */
#typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  animation: typing 6s steps(30, end);
}

.jumbotronStyle {
  padding-bottom: 50px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.jumbotronStyle h1 {
  font-size: 30pt;
}

.gradient1 {
  background: #bdc3c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c3e50,
    #bdc3c7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient2 {
  background: #757f9a; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #d7dde8, #757f9a); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #d7dde8,
    #757f9a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient3 {
  background: #6190e8; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #a7bfe8, #6190e8); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #a7bfe8,
    #6190e8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.gradient4 {
  background: #000046; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1cb5e0, #000046); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #1cb5e0,
    #000046
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

/* card*/
.center-cols > .col {
  float: none; /* disable the float */
  display: inline-block; /* make blocks inline-block */
  text-align: initial; /* restore text-align to default */
}

.brand-img{
  width: 100px;
  height: 100px;
  transform: translateY(-15px);
}

.maps{
  width: 100%;
  height:300px;
  border: 0;
}

.margin-title{
  transform: translateY(20px);
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (min-height: 320px) {
  .ui.footer.form-page {
    /* Increased specificity for SO snippet priority */
    position: absolute;
    margin-top: 25px;
    bottom: 0;
    width: 100%;
  }
}
